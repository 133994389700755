import {
  Text,
  VStack,
  Box,
  HStack,
  Image,
  Center,
  useBreakpointValue,
  Flex,
} from '@chakra-ui/react';

import { H5 } from '@/components/typography/Headings';
import StoryBadgeBanner from '@/assets/StoryBadgeBanner.png';
import Button from '@/components/button';
import ModalContainer from '@/components/modals/ModalContainer';

const STEP_BOX_MOBILE_SIZE = 36;
const STEP_BOX_SIZE = 50;

const STEPS = [
  {
    title: 'Create Your Design',
    mobileTitle: 'Create',
    description:
      'Use our AI design tools or upload your own custom design to bring your merch to life.',
    mobileDescription: 'Use our AI tools or upload your design.',
  },
  {
    title: 'Publish Your Design',
    mobileTitle: 'Publish',
    description:
      'Publish your design with a quick and simple 1-click process. Full IP registration options will be available soon!',
    mobileDescription: 'Launch your design in one click.',
  },
  {
    title: 'Sell Your Merch',
    mobileTitle: 'Sell',
    description:
      'Once your design is published, your design will be available for sale in our Merch Shop. You’ll earn 15% in royalties with every purchase and be able to claim them in the near future!',
    mobileDescription: 'Earn 15% royalties per sale.',
  },
];

type Props = {
  onClose?: () => void;
  onSelectTemplate: () => void;
};

const RoyaltiesAndBadgeModal = ({ onClose, onSelectTemplate }: Props) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <ModalContainer
      modalTitle="Design Merch & Earn Royalties!"
      onClose={onClose}
      width={{ base: 'auto', md: '498px' }}
    >
      <Box h="100%" pb={{ base: 0, md: '66px' }} overflowY="auto" position="relative">
        <Flex direction="column" h={{ base: 'auto', md: '100%' }}>
          <Text
            color="secondaryDarkGray.600"
            maxW={{ md: '400px' }}
            mb={{ base: '10px', md: '20px' }}
            textStyle="body"
          >
            Create unique merch on ABLO, publish your design, and start earning royalties on each
            sale.
          </Text>
          <Image
            borderRadius="8px"
            mb={{ base: '15px', md: '21px' }}
            src={StoryBadgeBanner}
            w="100%"
          />
          <VStack
            flex={1}
            overflow="auto"
            mb={{ base: '22px', md: 0 }}
            spacing={{ base: '18px', md: '20px' }}
          >
            {STEPS.map((step, index) => {
              const { title, description, mobileDescription, mobileTitle } = step;

              return (
                <HStack align="flex-start" gap={{ base: '14px', md: '20px' }} key={index} w="100%">
                  <Center
                    bg="#E4ECFF"
                    borderRadius={{ base: '4px', md: '12px' }}
                    h={{ base: `${STEP_BOX_MOBILE_SIZE}px`, md: `${STEP_BOX_SIZE}px` }}
                    w={{ base: `${STEP_BOX_MOBILE_SIZE}px`, md: `${STEP_BOX_SIZE}px` }}
                  >
                    <Text
                      color="brand.600"
                      fontWeight={600}
                      textStyle={isMobile ? 'bodySuperSmall' : 'subtitle'}
                    >
                      0{index + 1}
                    </Text>
                  </Center>
                  <Box flex={1}>
                    <H5 fontSize={{ base: '14px', md: '16px' }}>
                      {isMobile ? mobileTitle : title}
                    </H5>
                    <Text color="secondaryDarkGray.600" textStyle="body">
                      {isMobile ? mobileDescription : description}
                    </Text>
                  </Box>
                </HStack>
              );
            })}
          </VStack>
        </Flex>
        <HStack
          bottom={0}
          left={0}
          right={0}
          gap="9px"
          position={{ base: 'static', md: 'absolute' }}
          w={{ base: '100%', md: 'auto' }}
        >
          <Button onClick={onSelectTemplate} flex={1}>
            Start Designing
          </Button>
          <Button onClick={onClose} flex={1} secondary>
            Go Back
          </Button>
        </HStack>
      </Box>
    </ModalContainer>
  );
};

export default RoyaltiesAndBadgeModal;
